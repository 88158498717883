<template>
    <div>
        <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-1 mt-1">
                <h1 class="text-primary font-weight-bolder text-uppercase ml-1">
                  GDA - LISTE DES CLIENTS/PROSPECTS
                </h1>
            </div>
        </div>
        <b-overlay :show="showLoading" no-wrap />
        <b-card>
          <div class="custom-search">
        <!-- advance search input -->
          <b-row>
            <b-col md="3">
              <b-form-group label="Recherche">
                  <b-input-group>
                      <b-form-input
                                v-model="recherche"
                                :lazy="true"
                                id="basic-recherche"
                                class="d-inline-block"
                                type="text"
                                placeholder="Rechercher par nom ou prenom"
                                @keyup.enter="searchInput"
                            />
                            <b-input-group-append is-text>
                        <feather-icon
                            icon="SearchIcon"
                            class="cursor-pointer"
                            @click="searchInput"
                        />
                      </b-input-group-append>
                  </b-input-group>
                </b-form-group>
            </b-col>

            <b-col md="1" class="mt-1">
                  <b-button v-b-tooltip.hover.top="'Tout afficher'"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            class="btn-icon mt-1"
                            variant="primary"
                            @click="resetFilter"
                  >
                    <feather-icon icon="RefreshCwIcon"/>
                  </b-button>
              </b-col>
          </b-row>
          </div>
          </b-card>
        <div  class="mr-1 mb-1">
            <vue-good-table 
            :columns="columnsGDA"
              :pagination-options="{
              enabled: true,
              perPage: pageLength
            }"
              @on-selected-rows-change="selectionChanged"
              :rows="gdaListRows"
              :rtl="direction"
              :search-options="{
              externalQuery: searchTerm
            }"

            >
            <template slot="column-filter" slot-scope="props"></template>
            <div slot="emptystate">
              <center>Aucune donnée disponible dans le tableau</center>
            </div>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field === 'nom'"  @click="showClient(props.row)">
                <a style="text-decoration: underline;">{{props.row.nom}}</a>
              </span>
              <span v-else-if="props.column.field === 'statut_gda'" >
                <span class="orange-dot" v-if="props.row.statut_gda=='ORANGE'" v-b-tooltip.hover.top="'Gel des avoirs : A vérifier'" v-ripple.400="'rgba(255, 255, 255, 0.15)'"></span>
                <span class="red-dot" v-if="props.row.statut_gda=='ROUGE'" v-b-tooltip.hover.top="'Gel des avoirs : Interdit'" v-ripple.400="'rgba(255, 255, 255, 0.15)'"></span>
              </span>
              <span v-else>
                    {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
              <b-row class="mt-2 align-items-center">
                <b-col md="6" lg="5" xxl="3">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap ">
                      Affichage 1 à
                    </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['3','5','10']"
                      class="mx-1"
                      @input="(value)=>props.perPageChanged({currentPerPage:value})"
                    />
                    <span class="text-nowrap"> de {{ props.total }} entrées </span>
                  </div>
                </b-col>
            </b-row>
              <b-row class="mt-2 align-items-center">
                <!-- <b-col md="4">
                  <b-input-group>
                    <b-form-select v-model="selected" :options="options" />
                    <b-input-group-append>
                      <b-button  variant="primary" @click="action_masse(selected)">
                        <span>Valider </span>
                        <feather-icon icon="ArrowRightIcon" class="mr-50" />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-col> -->
                <b-col>
                  <b-pagination :per-page="pageLength" :total-rows="props.total" :value="1" align="right" first-number
                                last-number next-class="next-item" prev-class="prev-item"
                                @input="value => props.pageChanged({ currentPage: value })"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18"/>
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18"/>
                    </template>
                  </b-pagination>
              </b-col>
              </b-row>
            </template>
            </vue-good-table>

        </div>
    </div>
</template>
<script>
import { BPagination, BFormGroup,BFormInput , BCard, BFormSelect,BTabs,BTab, BDropdown, BDropdownItem, BRow, BCol, BButton, BInputGroup, BInputGroupAppend, BOverlay, BModal, BImg, VBToggle,VBTooltip  } from 'bootstrap-vue'
// import Ripple from 'vue-ripple-directive'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'
import { VueGoodTable } from 'vue-good-table'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Ripple from 'vue-ripple-directive'
import moment from 'moment';

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    BOverlay,
    BCard,
    BButton,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    VueGoodTable,
    BPagination,
    BInputGroupAppend,
    BFormSelect,
    BTabs,
    BTab,
    VBTooltip,
    BInputGroup
    // eslint-disable-next-line vue/no-unused-components
    
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
  },
  mounted(){
    this.showLoading = true
    this.getData()
    this.redirected=this.$route.params.redirected
    if(this.redirected)
      this.showAlertMessage()
  },
  data() {
    return {
      currentUser:JSON.parse(localStorage.getItem('userData')),
      pageLength: 3,
      dir: false,
      searchTerm:null,
      columnsGDA: [
        { label: 'Nom', field: 'nom', thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
        { label: 'Prénom', field: 'prenom', thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
        { label: 'Date de naissance', field: 'date_naissance',type:'date', sortable: true,dateInputFormat: 'yyyy-MM-dd',dateOutputFormat: 'dd/MM/yyyy', thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
        { label: 'Statut GDA', field: 'statut_gda', sortable: true,dateInputFormat: 'yyyy-MM-dd',dateOutputFormat: 'dd/MM/yyyy', thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
      ],
      gdaListRows: [],
      showLoading: false,
      selectedrows_masse: [],
      selected: null,
      options: [
        { value: null, text: 'Action de masse' },
        { value: 'Valider', text: ' Valider' }
      ],
      total:0,
      gdaList:[],
      recherche:null,
      redirected:false
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    }
  },
  watch: {
    recherche(){
      this.searchInput()
    }
  },
  methods: {
    selectionChanged(params) {
      this.selectedrows_masse = params
    },
    messageToast(text, title, variant) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title,
          icon: 'BellIcon',
          text,
          variant
        }
      })
    },
    getData(){
      this.showLoading= true
      this.$http.get('/gda/getListGDA')
      .then(res=>{
        if(res.data){
          this.gdaList=res.data
          this.gdaList.date_naissance=moment(this.gdaList.date_naissance,'YYYY-MM-DD').format('DD-MM-YYYY')
          this.gdaListRows=this.gdaList
          this.showLoading=false
        }
      })
    },
    showClient(row){
      this.$router.push({
                name: 'tiers-list',
                params: {
                  'id': row.id,
                  'type':'PERSONNE_PHYSIQUE',
                  'interfaceChosen': 'PARTICULIER'
                }
              })
    },
    resetFilter() {
      this.searchTerm = null
      this.recherche = null
    },
    searchInput(){
      this.showLoading = true
      // setTimeout(() => {
        if(this.recherche != null) {
          this.searchTerm = this.recherche
          this.showLoading = false
        }
        this.showLoading = false
      // }, 500);
    },
    showAlertMessage(){
      let str="<div style='text-align: justify;'>Attention, le contrôle du gel des avoirs a identifié votre client/prospect comme susceptible d\'appartenir au registre national des gels.<br/><br/>";
      str=str+"Celui-ci est dorénavant bloqué à toute modification ou souscription.<br/><br/>";
      str=str+"Pour le débloquer, vous devez procéder à différents contrôles (pour plus de détails, voir l\'onglet <a target='_blank' href='https://bubbleinsupport.zendesk.com/hc/fr/articles/8612072274333-Qu-est-ce-que-le-gel-des-avoirs-'  style='text-decoration: underline;'> FAQ</a>)</div>";
      this.$swal({
        html: str ,
        icon: 'warning',
        confirmButtonText: 'OK',
        confirmButtonColor: '#4d25bc',

      })
    }
    
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
<style  >
.bordereau-card::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #00000029;
    z-index: 99;
}
.red-dot {
  height: 20px;
  width: 20px;
  background-color: #ff4d4d;
  border-radius: 50%;
  display: inline-block;
}
.orange-dot {
  height: 20px;
  width: 20px;
  background-color: orange;
  border-radius: 50%;
  display: inline-block;
}

my-actions{
  margin: 0em 0em 4em 4em;
}
.btn-primary{
  margin-right:2em;
  background-color: #4d25bc;
}
.order-3{
  margin-right:2em;
  background-color: white;
  border-color: red;
  border-width:1px;
  border-style: solid;
}
.order-3:hover{
  margin-right:2em;
  background-color: red;
  color: white;
  border-color: red;
  border-width:1px;
  border-style: solid;
}
.vgt-global-search{
  display: none;
}
</style>
